import * as React from 'react'

import { Icon } from '../../types'

const DribbbleIcon: Icon = ({ fill = 'white' }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C3.14016 0 0 3.14019 0 7C0 10.8598 3.14016 14 7 14C10.8598 14 14 10.8598 14 7C14 3.14019 10.8598 0 7 0ZM11.5616 3.30136C12.3475 4.26869 12.8313 5.48966 12.8715 6.82062C11.4158 6.51926 10.0977 6.55462 8.93404 6.78278C8.72334 6.29005 8.50523 5.82062 8.28509 5.37697C9.55235 4.83104 10.6556 4.13375 11.5616 3.30136ZM10.7731 2.49993C9.87371 3.32085 8.83147 3.92867 7.76427 4.37742C6.98097 2.945 6.23154 1.86633 5.775 1.25333C6.17032 1.16911 6.57995 1.12393 7 1.12393C8.4354 1.12393 9.75151 1.64199 10.7731 2.49993ZM4.63984 1.62041C4.98459 2.06714 5.80572 3.18567 6.68777 4.77709C4.47095 5.49933 2.31992 5.62101 1.28629 5.63232C1.71713 3.83253 2.97749 2.35262 4.63984 1.62041ZM1.13006 6.7591C1.14115 6.7591 1.15187 6.75918 1.16318 6.75918C1.8774 6.75918 3.18594 6.71115 4.72646 6.43024C5.60536 6.26997 6.43879 6.05425 7.22081 5.7869C7.42439 6.19264 7.62677 6.62064 7.82301 7.06923C6.72801 7.42177 5.8046 7.92904 5.07315 8.43271C3.84658 9.27739 2.9873 10.2145 2.51811 10.7939C1.64939 9.76944 1.12392 8.4453 1.12392 7C1.12392 6.91923 1.12677 6.83913 1.13006 6.7591ZM3.328 11.5826C3.73194 11.074 4.55412 10.1482 5.74346 9.33591C6.55171 8.78383 7.3879 8.37367 8.24875 8.10445C8.74822 9.39907 9.17464 10.8295 9.42887 12.3484C8.68798 12.6862 7.86602 12.8761 7 12.8761C5.61143 12.8761 4.33474 12.3909 3.328 11.5826ZM10.4629 11.7432C10.2009 10.3483 9.8101 9.03664 9.35836 7.83793C10.4695 7.65046 11.618 7.68912 12.7975 7.95407C12.5431 9.50435 11.6805 10.8519 10.4629 11.7432Z"
      fill={fill}
    />
  </svg>
)

export default DribbbleIcon
