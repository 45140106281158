import * as React from 'react'
import CarouselNuka from 'nuka-carousel'
import { useTheme } from '@bestyled/primitives'

export default (props) => {
  const theme = useTheme()
  return (
    <CarouselNuka
      enableKeyboardControls
      defaultControlsConfig={{
        nextButtonText: '>',
        prevButtonText: '<',
        prevButtonStyle: {
          backgroundColor: theme.colors.brand
        },
        nextButtonStyle: {
          backgroundColor: theme.colors.brand
        },
        pagingDotsStyle: {
          fill: theme.colors.brand,
          paddingLeft: '10px'
        }
      }}
      {...props}
    />
  )
}
