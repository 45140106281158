import prism from './prism'

export default {
  prism,
  primary: '#000',
  brand: '#003764', // '#8B3765',
  brandBg: '#fff',
  secondary: '#00A0DD',
  headings: '#00A0DD',
  button: '#fff',
  buttonBg: '#003764',
  buttonHover: '#003764',
  buttonHoverBg: '#fff',
  grey: '#55565A',
  secondarygrey: '#ddd',
  background: '#fafafa',
  accent: '#003764',
  hover: 'rgba(0, 0, 0, 0.07)',
  gradient: 'linear-gradient(180deg, rgba(217, 219, 224, 0) 0%, #D9DBE0 100%)',
  articleText: '#55565A',
  track: 'rgba(8, 8, 11, 0.3)',
  progress: '#000',
  card: '#fff',
  error: '#FF4338',
  success: '#026937',
  errorBackground: 'rgba(238, 86, 91, 0.1)',
  horizontalRule: 'rgba(8, 8, 11, 0.15)',
  inputBackground: 'rgba(0, 0, 0, 0.05)',
  darken: [
    'rgba(0, 0, 0, 0.125)',
    'rgba(0, 0, 0, 0.25)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.75)'
  ],
  modes: {
    dark: {
      brand: '#A09C92',
      brandBg: '#000',
      grey: '#73737D',
      secondarygrey: '#222',
      primary: '#fff',
      secondary: '#F3EA9B',
      accent: '#26CAD3',
      background: '#003764',
      hover: 'rgba(255, 255, 255, 0.07)',
      gradient:
        'linear-gradient(180deg, #111216 0%, rgba(66, 81, 98, 0.36) 100%)',
      articleText: '#fff',
      track: 'rgba(255, 255, 255, 0.3)',
      progress: '#fff',
      card: '#1D2128',
      error: '#E24301',
      success: '#6CC04A',
      errorBackground: 'rgba(238, 86, 91, 0.1)',
      horizontalRule: 'rgba(255, 255, 255, 0.15)',
      inputBackground: 'rgba(255, 255, 255, 0.07)'
    }
  }
}
