/* eslint-disable no-restricted-globals */
// TYPEFORM = 'https://synchealth.typeform.com/to/K8oyPb?utm_source=xxxxx&env=xxxxx&host=xxxxx&utm_cid=xxxxx&cus=xxxx&org=xxxxx'

import { useAnalytics } from '@bestyled/contrib-analytics-google'
import React from 'react'
import * as typeformEmbed from '@typeform/embed'
import { usePortal } from '@bestyled/primitives'
import Button from './Button'
import Headings from './Headings'
//import { graphql, useStaticQuery } from 'gatsby'

export interface TypeformProps {
  url: string
  hostname?: string
  hideHeaders?: boolean
  hideFooter?: boolean
  opacity?: number
  buttonText?: string
  //  onSubmit?: Function
  children?: string
  customer?: string
  organization?: string
}

const Typeform = ({ children, ...props }: TypeformProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { onEvent } = useAnalytics()

  let path

  if (typeof window !== 'undefined' && window.location) {
    path = location.href
  }

  React.useEffect(() => {
    if (location.href.indexOf('#register') !== -1) {
      if (!isOpen) {
        setIsOpen(true)
      }
    } else {
      setIsOpen(false)
    }
  }, [path])

  return (
    <Headings.h6>
      <Button
        href="#"
        onClick={(e) => {
          e.preventDefault()
          if (history.pushState) {
            history.pushState(
              {},
              document.title,
              `${window && window.location ? location.href : ''}#register`
            )
          }
          setIsOpen(true)
          onEvent({
            category: 'register',
            action: 'start',
            label: props.url
          })
        }}
        type="button"
      >
        {children}
      </Button>
      {isOpen && <InnerTypeform visible={isOpen} {...props} />}
    </Headings.h6>
  )
}

const InnerTypeform = ({
  url = 'https://synchealth.typeform.com/to/K8oyPb',
  hostname = window && window.location && window.location.hostname,
  hideHeaders = false,
  hideFooter = false,

  // Widget options
  opacity = 100,
  visible = false,
  buttonText = 'Start',
  customer = '',
  organization = ''

}: TypeformProps & { visible: boolean }) => {
  const typeformRef = usePortal('typeform')
  const { utms, onEvent } = useAnalytics()

  typeformRef.style.position = 'fixed'
  typeformRef.style.top = '0'
  typeformRef.style.left = '0'
  typeformRef.style.width = '100%'
  typeformRef.style.height = '100%'
  typeformRef.style.overflow = 'hidden%'
  typeformRef.style.opacity = '1'
  typeformRef.style.zIndex = '1024'

  let url_ = `${url}?host=${hostname}`

  if (utms.utm_source) {
    url_ = `${url_}&utm_source=${utms.utm_source}`
  }

  if (utms.utm_cid) {
    url_ = `${url_}&utm_cid=${utms.utm_cid}`
  }
// add organization id [which organization inside of the tenant record] that we want to associate to the registration link
  if (organization) {
    url_ = `${url_}&org=${organization}`
  }

// add the customer [tenent] id that is associated with this registration link
  if(customer) {
    url_ = `${url_}&cus=${customer}`
  }
  
  const onSubmit = () => {
    onEvent({
      category: 'register',
      action: 'submit',
      label: url
    })
    setTimeout(() => {
      window.location.href = `https://${window.location.hostname}/continue-specialists`
    }, 1000)
  }

  React.useEffect(() => {
    const options = {
      hideHeaders,
      hideFooter,
      opacity,
      buttonText,
      onSubmit
    }

    if (typeformRef) {
      typeformEmbed.makeWidget(typeformRef, url_, options)
    }
  }, [])

  return null
}

export default Typeform
