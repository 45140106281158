import React, { ReactNode } from 'react'
import { MediaSizeProvider, LinkProvider } from '@bestyled/contrib-common'
import { Link, navigate } from 'gatsby'
import {
  ColorModeProvider,
  ThemeProvider as StyledThemeProvider
} from '@bestyled/contrib-use-color-mode'

import merge from 'lodash/merge'

import { theme as themeSlipway, themeAh } from '@slipway/theme-components'

const cwdTheme = require('@content/theme/index').default

interface GatsbyRootProps {
  element: ReactNode
}

export const wrapRootElement = (
  gatsbyRootProps: GatsbyRootProps,
  pluginOptions: { uitheme?: any }
) => {
  const theme = merge(
    pluginOptions.uitheme === 'slipway-ah' ? themeAh : themeSlipway,
    cwdTheme
  )

  return (
    <ColorModeProvider>
      <StyledThemeProvider theme={theme}>
        <LinkProvider Link={Link} navigate={navigate}>
          <MediaSizeProvider>{gatsbyRootProps.element}</MediaSizeProvider>
        </LinkProvider>
      </StyledThemeProvider>
    </ColorModeProvider>
  )
}
