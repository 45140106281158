module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":10000,"linkImagesToOriginal":false,"quality":80,"withWebp":true}},{"resolve":"@raae/gatsby-remark-oembed","options":{"providers":{"include":["Instagram"]}}},{"resolve":"gatsby-remark-embed-video","options":{"width":680,"ratio":1.77,"height":400,"related":false,"noIframeBorder":true,"urlOverrides":[{"id":"youtube"}]}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-numbered-footnotes"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noreferrer"}}],"remarkPlugins":[null],"defaultLayouts":{},"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../slipway-theme/packages/slipway-theme-gatsby/gatsby-browser.ts'),
      options: {"plugins":[],"contentArticles":"content/articles","contentSpecialists":"content/specialists","contentPages":"content/pages","contentLocales":"content/locales","basePath":"/","uiTheme":"slipway","sources":{"local":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quorum Health SyncTalk Program","short_name":"Quorum Health SyncTalk Program","start_url":"/","background_color":"#8B3765","theme_color":"#8B3765","display":"standalone","icon":"content/theme/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d2ae5eb63c13820328fd0f5ed21de6d"},
    },{
      plugin: require('../node_modules/@bestyled/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-84061010-11"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/home/runner/work/slipway-synctalk-quorumhealth/slipway-synctalk-quorumhealth","configDir":".config"},
    }]
