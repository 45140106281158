import * as React from 'react'

import { Icon } from '../../types'

const StackoverflowIcon: Icon = ({ fill = 'white' }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 73.1 86.6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.8,78.9V55.7h7.7v30.9H0V55.7h7.7v23.2H61.8z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2,53.5L54,61.4l1.6-7.6l-37.8-7.9L16.2,53.5z M21.2,35.5l35,16.3l3.2-7l-35-16.4
	C24.4,28.4,21.2,35.5,21.2,35.5z M30.9,18.3L60.6,43l4.9-5.9L35.8,12.4C35.8,12.4,30.9,18.3,30.9,18.3z M50.1,0l-6.2,4.6l23,31
	l6.2-4.6L50.1,0z M15.4,71.1H54v-7.7H15.4V71.1z"
      fill={fill}
    />
  </svg>
)

export default StackoverflowIcon
